import SlickSlider from "react-slick";

export const Slider = ({
  children,
  infinite = true,
  hasArrows = true,
  autoplay = false,
  slidesToShow = 1,
  breakpoints = [],
}) => {
  const settings = {
    infinite,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    autoplay,
    speed: 2000,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: hasArrows ? <NextArrow /> : <></>,
    prevArrow: hasArrows ? <PrevArrow /> : <></>,
    responsive: breakpoints.length ? breakpoints : [],
  };

  return <SlickSlider {...settings}>{children}</SlickSlider>;
};

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, position: "absolute", left: "0px", zIndex: 10 }}
      onClick={onClick}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, position: "absolute", right: "0px", zIndex: 10 }}
      onClick={onClick}
    />
  );
}
