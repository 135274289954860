import { Text, Container, Flex, useBreakpointValue } from "@chakra-ui/react";

import OptimizedImage from "../../shared/OptimizedImage";

import Harvard from "../../../public/images/home/marquee/Harvard.svg";
import Stanford from "../../../public/images/home/marquee/Stanford.svg";
import MIT from "../../../public/images/home/marquee/MIT.svg";
import Princeton from "../../../public/images/home/marquee/Princeton.svg";
import UPenn from "../../../public/images/home/marquee/UPenn.svg";
import Dartmouth from "../../../public/images/home/marquee/Dartmouth.svg";
import Brown from "../../../public/images/home/marquee/Brown.svg";
import Duke from "../../../public/images/home/marquee/Duke.svg";
import Columbia from "../../../public/images/home/marquee/Columbia.svg";
import Yale from "../../../public/images/home/marquee/Yale.svg";
import Cornell from "../../../public/images/home/marquee/Cornell.svg";
import Northwestern from "../../../public/images/home/marquee/Northwestern.svg";
import { Slider } from "../../shared/Slider";

const images = [
  { imported: Harvard, name: "Harvard University", link: "/example-essays/harvard" },
  { imported: Stanford, name: "Stanford University", link: "/example-essays/stanford" },
  { imported: MIT, name: "Massachusetts Institute of Technology", link: "/example-essays/mit" },
  { imported: Princeton, name: "Princeton University", link: "/example-essays/princeton" },
  { imported: UPenn, name: "University of Pennsylvania", link: "/example-essays/upenn" },
  { imported: Dartmouth, name: "Dartmouth College", link: "/example-essays/dartmouth" },
  { imported: Brown, name: "Brown University", link: "/example-essays/brown" },
  { imported: Duke, name: "Duke University", link: "/example-essays/duke" },
  {
    imported: Columbia,
    name: "Columbia University in the City of New York",
    link: "/example-essays/columbia",
  },
  { imported: Yale, name: "Yale University", link: "/example-essays/yale" },
  { imported: Cornell, name: "Cornell University", link: "/example-essays/cornell" },
  { imported: Northwestern, name: "Northwestern University", link: "/example-essays/northwestern" },
];

const LinkWrapper = ({ isLinked, children, href }) => {
  if (isLinked) {
    return <a href={href}>{children}</a>;
  } else {
    return children;
  }
};

export function StaticCollegeList({
  hueRotate = 75,
  limit = { base: 8, md: 12 },
  isLinked = true,
  ...rest
}) {
  const nItems = useBreakpointValue(limit);

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Container
      maxW="container.2xl"
      bg="#17163B"
      pt={{ base: "1rem", md: "4rem" }}
      pb={"10rem"}
      {...rest}
    >
      <Text
        color="#F0F2F5"
        fontSize="xs"
        fontWeight="700"
        textTransform="uppercase"
        my={10}
        textAlign="center"
      >
        Explore your dream schools
      </Text>

      {isMobile ? (
        <Flex wrap="wrap" gap={4} justify="center">
          {images.slice(0, nItems).map(({ imported, name, link }) => (
            <Flex
              key={name}
              textAlign="center"
              pos="relative"
              border="1px solid #2C3748"
              px={4}
              py={4}
              borderRadius="0.5rem"
              cursor="pointer"
              transition="0.3s ease-in-out"
              height={"8rem"}
              width={"8rem"}
              _hover={{
                boxShadow: "-3px 3px 0px 0px #454562",

                "#home_college_name": {
                  opacity: 1,
                },
              }}
            >
              <LinkWrapper isLinked={isLinked} href={link}>
                <OptimizedImage
                  src={imported}
                  width={60}
                  height={60}
                  objectFit="contain"
                  alt={name}
                  title={name}
                />

                <Text
                  id="home_college_name"
                  textTransform="uppercase"
                  fontWeight={700}
                  textAlign="center"
                  color="#fff"
                  fontSize="10px"
                  transition="0.3s ease-in-out"
                  opacity={0}
                >
                  {name}
                </Text>
              </LinkWrapper>
            </Flex>
          ))}
        </Flex>
      ) : (
        <Slider
          hasArrows
          autoplay
          infinite
          slidesToShow={6}
          breakpoints={[
            {
              breakpoint: 1370,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 1160,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 950,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 678,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 455,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {images.slice(0, nItems).map(({ imported, name, link }) => (
            <Flex
              key={name}
              textAlign="center"
              pos="relative"
              border="1px solid #2C3748"
              p={10}
              borderRadius="0.5rem"
              cursor="pointer"
              transition="0.3s ease-in-out"
              height={"16rem"}
              width={"16rem"}
              _hover={{
                boxShadow: "-3px 3px 0px 0px #454562",

                "#home_college_name": {
                  opacity: 1,
                },
              }}
            >
              <LinkWrapper isLinked={isLinked} href={link}>
                <OptimizedImage
                  src={imported}
                  width={140}
                  height={140}
                  objectFit="contain"
                  alt={name}
                  title={name}
                />

                <Text
                  id="home_college_name"
                  textTransform="uppercase"
                  fontWeight={700}
                  textAlign="center"
                  color="#fff"
                  fontSize="xs"
                  transition="0.3s ease-in-out"
                  opacity={0}
                >
                  {name}
                </Text>
              </LinkWrapper>
            </Flex>
          ))}
        </Slider>
      )}
    </Container>
  );
}
