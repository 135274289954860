import { useEffect } from "react";

import PageInfo from "@/components/PageInfo";
import { getPageProfiles } from "@/utils/admin/user-utils";
import { shuffle } from "@/utils/random";
import { DATABASE_SIZE } from "@/utils/profile";
import { Hero } from "@/components/home/Hero";
import { AboutUs } from "@/components/home/AboutUs";
import { ProfilesDescription } from "@/components/home/ProfilesDescription";
import { StaticCollegeList } from "@/components/home/StaticCollegeList";
import { EssaysDescription } from "@/components/home/EssaysDescription";
import { OtherTestimonials } from "@/components/home/OtherTestimonials";
import { Articles } from "@/components/home/Articles";
import { FreeProfile } from "@/components/home/FreeProfile";
import Footer from "@/components/Footer";
import { getAllFilesFrontMatter } from "@/utils/blog/mdx";

const Home = ({ previewProfiles, testimonials, posts }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      shuffle(previewProfiles, Math.random() * 10000, 0, previewProfiles.length);
    }
  }, []);

  return (
    <>
      <PageInfo
        overrideTitle={`Get exclusive insights from ${DATABASE_SIZE.applications}+ applications that worked.`}
        overrideOGImage="/api/og/generic"
      />

      <Hero previewProfiles={previewProfiles} testimonials={testimonials.preview} />

      <AboutUs />

      <ProfilesDescription />

      <EssaysDescription />

      <OtherTestimonials testimonials={testimonials} />

      <StaticCollegeList id="afterTestimonials" />

      <Articles posts={posts} />

      <FreeProfile />

      <Footer posts={posts} />
    </>
  );
};

export default Home;

export async function getStaticProps() {
  const previewProfiles = await getPageProfiles();
  const testimonials = require("@/utils/data/testimonial-data.json");
  const posts = await getAllFilesFrontMatter("blog");

  const slicedPosts = posts.slice(0, 5);

  shuffle(previewProfiles, Math.random() * 10000, 0, previewProfiles.length);

  return {
    props: { previewProfiles, testimonials, posts: slicedPosts },
    // Generate new preview profiles every day
    revalidate: 1 * 24 * 60 * 60,
  };
}
