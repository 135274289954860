import { Flex, Box, Text } from "@chakra-ui/react";

import { GradientText, LinkButton, SkewedContainer } from "@/components/shared/generic";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import OptimizedImage from "@/components/shared/OptimizedImage";

export const Articles = ({ posts }) => {
  const textSuccessful = {
    from: "#EC538A 61.12%",
    to: "#842A62 139.29%",
    fontSize: { base: "0.95em", md: "1em" },
    degree: "200deg",
    display: "inline",
    mobileBg: "#EC538A",
  };

  return (
    <Box zIndex={1} position="relative">
      <SkewedContainer
        bg="white"
        containerProps={{
          maxW: "container.xl",
          paddingInlineStart: { base: "2rem", xl: "1rem" },
          paddingInlineEnd: { base: "2rem", xl: "1rem" },
        }}
        top={{ base: "-8em", sm: "-8em", md: "-8em", lg: "-8em", xl: "-8em", "2xl": "-11em" }}
      >
        <Flex my={{ base: 0, md: 7 }} mb={{ base: 20, md: 0 }} direction="column">
          <Flex justify="space-between" direction={{ base: "column-reverse", md: "row" }}>
            <Box width={{ base: "100%", md: "40%" }}>
              <LinkButton
                mt={8}
                colorScheme="transparent"
                href={"/blog"}
                shadow="-4px 4px 0px 0px #6A7684"
                rightIcon={<ArrowForwardIcon />}
                background="#FFF"
                color="#000"
                border="1px solid #000"
                width={{ base: "100%", md: "fit-content" }}
              >
                Most Read Articles
              </LinkButton>
            </Box>

            <Box
              fontSize="2.5rem"
              fontWeight={600}
              width={{ base: "100%", md: "60%" }}
              textAlign={{ base: "center", md: "right" }}
            >
              <Text fontSize="2rem" display="inline-block" mr={2}>
                🤩
              </Text>
              Learn about{" "}
              <GradientText {...textSuccessful}>
                college selection, extracurriculars, essays
              </GradientText>{" "}
              and more in our Blog
            </Box>
          </Flex>

          <Flex gap={8} mt={10} wrap="wrap" justify="center">
            {posts.slice(0, 3).map((post, index) => (
              <Flex
                key={index}
                p={4}
                border="1px solid #EC538A"
                borderRadius="0.5rem"
                transition="0.3s ease-in-out"
                direction="column"
                justify="space-between"
                maxWidth="24.5rem"
                _hover={{
                  boxShadow: "-4px 4px 0px 0px #EC538A",
                }}
              >
                <Box>
                  <Box position="relative">
                    <Box
                      pos="absolute"
                      top={4}
                      left={6}
                      bg="#000"
                      boxShadow="-1px 1px 0px 0px #FFF"
                      border="1px solid #FFF"
                      borderRadius="0.25rem"
                      color="#fff"
                      py={1}
                      px={2}
                      fontSize="0.625rem"
                      fontWeight={700}
                      zIndex={2}
                      textTransform="uppercase"
                    >
                      {post.tags[0]}
                    </Box>

                    <OptimizedImage
                      src={post.img}
                      width={420}
                      height={290}
                      objectFit="contain"
                      alt={`Post ${index + 1}`}
                      sx={{
                        borderRadius: "2xl",
                      }}
                    />
                  </Box>

                  <Text
                    color="#414B5A"
                    fontWeight={600}
                    fontFamily="'Raleway', sans-serif"
                    fontSize="1.18rem"
                    mt={4}
                  >
                    {post.summary}
                  </Text>
                </Box>

                <Box>
                  <ReadMoreButton slug={post.slug} />
                </Box>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </SkewedContainer>
    </Box>
  );
};

const ReadMoreButton = ({ slug }) => (
  <LinkButton
    target="_blank"
    mt={2}
    colorScheme="transparent"
    href={`/blog/${slug}`}
    rightIcon={<ArrowForwardIcon />}
    background="#FFF"
    color="#000"
    width={{ base: "100%", md: "fit-content" }}
    fontSize="sm"
    p={0}
    _hover={{
      "& > span": {
        transition: "0.3s ease-in-out",
        transform: "translateX(6px)",
      },
    }}
  >
    Read More
  </LinkButton>
);
