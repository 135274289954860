import { Container, Flex, Box, Text } from "@chakra-ui/react";

import { GradientText, LinkButton } from "@/components/shared/generic";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import OptimizedImage from "@/components/shared/OptimizedImage";

import profile from "../../../public/images/home/profiles/profile.png";

export const FreeProfile = () => {
  const textSuccessful = {
    from: "#EC538A 61.12%",
    to: "#842A62 139.29%",
    fontSize: { base: "0.95em", md: "1em" },
    degree: "200deg",
    display: "inline",
    mobileBg: "#EC538A",
  };

  return (
    <Container
      maxW="container.xl"
      paddingInlineStart={{ base: "2rem", "2xl": "1rem" }}
      paddingInlineEnd={{ base: "2rem", "2xl": "1rem" }}
      position="relative"
      my={40}
      mb={"14rem"}
    >
      <Flex
        borderRadius="16px"
        border="2px solid rgba(255, 255, 255, 0.25)"
        background="linear-gradient(195deg, #451A39 20.03%, #17163B 68.69%)"
        boxShadow="-5px 5px 0px 0px #2D3644"
        color="#fff"
        p={{ base: 6, md: 12 }}
        justify="center"
        align="center"
        direction={{ base: "column", md: "row" }}
        gap={10}
        position="relative"
      >
        <Text fontSize="4xl" position="absolute" top="-6rem" right="10rem">
          💥
        </Text>

        <Flex
          direction="column"
          position="relative"
          width={{ base: "100%", md: "50%" }}
          textAlign={{ base: "center", md: "left" }}
        >
          <Box
            fontSize={{ base: "3rem", md: "4rem" }}
            fontFamily="'Raleway', sans-serif"
            fontWeight={700}
          >
            Read a <GradientText {...textSuccessful}>Free</GradientText> profile.
          </Box>

          <Text fontSize="md" fontFamily="'Raleway', sans-serif" fontWeight={700}>
            You made it to the bottom, so the first one is on us. Enjoy!
          </Text>

          <Flex gap={4} direction={{ base: "column", md: "row" }} mt={6}>
            <LinkButton
              colorScheme="transparent"
              href={"/profiles"}
              shadow="-4px 4px 0px 0px #6A7684"
              rightIcon={<ArrowForwardIcon />}
              background="#FFF"
              color="#000"
              border="1px solid #000"
              width={{ base: "100%", md: "fit-content" }}
            >
              Get more profiles
            </LinkButton>

            <LinkButton
              colorScheme="transparent"
              href={"/profile/obeNZeeu28aj8RjZNQ7PNTPrdkC2"}
              shadow="-4px 4px 0px 0px #FFF"
              rightIcon={<ArrowForwardIcon />}
              color="#fff"
              border="1px solid #fff"
              width={{ base: "100%", md: "fit-content" }}
            >
              Read Ananth's Profile
            </LinkButton>
          </Flex>
        </Flex>

        <Flex width={{ base: "100%", md: "50%" }} justify="center">
          <OptimizedImage src={profile} width={322} height={210} objectFit="contain" />
        </Flex>
      </Flex>
    </Container>
  );
};
