export function mulberry32(a) {
  return function () {
    var t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

// Standard Normal variate using Box-Muller transform.
export function gaussianRandom(mean = 0, stdev = 1) {
  let u = 1 - Math.random(); //Converting [0,1) to (0,1)
  let v = Math.random();
  let z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  // Transform to the desired mean and standard deviation:
  return z * stdev + mean;
}

export function shuffle(array, seed, startIndex, endIndex) {
  let currentIndex = endIndex,
    temporaryValue,
    randomIndex;
  seed = seed || 1;

  let random = function () {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };

  // While there remain elements to shuffle...
  while (startIndex !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(random() * (endIndex - startIndex)) + startIndex;

    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];

    array[currentIndex] = array[randomIndex];

    array[randomIndex] = temporaryValue;
  }

  return array;
}
