import { useEffect, useRef, useState } from "react";

export const TypeAnimation = ({ isTrue, text1 = "", text2 = "", speed = 50 }) => {
  return <TypingText texts={isTrue ? [text1] : [text2]} speed={speed} condition={isTrue} />;
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function TypingText({ texts, speed, condition }) {
  const [displayText, setDisplayText] = useState("");
  const prevConditionRef = useRef();

  const startTyping = async () => {
    for (let i = 0; i < texts.length; i++) {
      for (let j = 0; j <= texts[i].length; j++) {
        setDisplayText(texts[i].slice(0, j));
        await sleep(speed);
      }
      await sleep(1000); // Pause between texts
    }
  };

  useEffect(() => {
    if (condition !== prevConditionRef.current) {
      setDisplayText("");
      startTyping();
      prevConditionRef.current = condition;
    }
  }, [texts, speed, condition]);

  return <div>{displayText}</div>;
}
