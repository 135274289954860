import { chakra, Flex, Box } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import { GradientText, LinkButton, SkewedContainer } from "@/components/shared/generic";
import OptimizedImage from "@/components/shared/OptimizedImage";
import { useDevice } from "@/contexts/DeviceContext";

export const ProfilesDescription = () => {
  const { isMobile } = useDevice();

  const textSuccessful = {
    from: "#EC538A 61.12%",
    to: "#842A62 139.29%",
    fontSize: { base: "0.95em", md: "1em" },
    degree: "200deg",
    display: "inline",
    mobileBg: "#EC538A",
  };

  return (
    <Box zIndex={1} position="relative" bg="#FCEFF7" mt={{ base: 10, md: 60 }}>
      <SkewedContainer
        bg="#FCEFF7"
        containerProps={{
          maxW: "container.xl",
          paddingInlineStart: { base: "2rem", xl: "1rem" },
          paddingInlineEnd: { base: "2rem", xl: "1rem" },
        }}
        top={{ base: "-6em", sm: "-6em", md: "-6em", lg: "-6em", xl: "-7em", "2xl": "-8em" }}
      >
        <Flex
          flexDir={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          gap={{ base: "4rem", md: "4rem" }}
          mt={10}
          pb={{ base: "4rem", md: "8rem" }}
          textAlign={{ base: "center", md: "left" }}
        >
          <Flex flexDir="column" width={{ base: "100%", md: "50%" }}>
            <chakra.p fontSize="xs" fontWeight={700} textTransform="uppercase" color="#EF38AA">
              FIND YOUR COLLEGE TWIN
            </chakra.p>

            <Box
              fontWeight={600}
              fontSize="2.875rem"
              fontFamily="'Raleway', sans-serif"
              lineHeight="116%"
              letterSpacing="-0.02875rem"
            >
              Meet a successful admit, just like{" "}
              <GradientText {...textSuccessful}>your future self</GradientText>
            </Box>

            <chakra.p fontSize="md" mt={4}>
              Find profiles of successful admits with the same background, interests, and stats as
              you. Learn what they did to get to your dream school and follow their path.
            </chakra.p>

            {!isMobile && (
              <LinkButton
                mt={8}
                colorScheme="transparent"
                href={"/profiles"}
                shadow="-4px 4px 0px 0px #EF38AA"
                rightIcon={<ArrowForwardIcon />}
                background="#FFF"
                color="#000"
                border="1px solid #000"
                width={{ base: "100%", md: "fit-content" }}
              >
                Read more
              </LinkButton>
            )}
          </Flex>

          <Box width={{ base: "100%", md: "50%" }}>
            <OptimizedImage
              src={"/images/home/profiles/other-profiless.png"}
              alt="Profiles"
              width="640px"
              height="490px"
              objectFit="cover"
            />

            {isMobile && (
              <LinkButton
                mt={8}
                colorScheme="transparent"
                href={"/profiles"}
                shadow="-4px 4px 0px 0px #EF38AA"
                rightIcon={<ArrowForwardIcon />}
                background="#FFF"
                color="#000"
                border="1px solid #000"
                width={{ base: "100%", md: "fit-content" }}
              >
                Read more
              </LinkButton>
            )}
          </Box>
        </Flex>
      </SkewedContainer>
    </Box>
  );
};
