import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const FadeInOutText = ({ condition, text1, text2, ...other }) => {
  const [animationKey, setAnimationKey] = useState(0);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    // Trigger animation on each change of condition
    setAnimationKey((prevKey) => prevKey + 1);
    setShowText(true);

    // Clear text after the fade-in animation duration
    const timeoutId = setTimeout(() => {
      setShowText(false);
    }, 2000); // Adjust the duration based on your animation time

    return () => {
      clearTimeout(timeoutId);
    };
  }, [condition]);

  return (
    <Box
      key={animationKey}
      style={{
        animation: showText ? "fadeInOut 5s linear infinite" : "none",
      }}
      css={{
        "@keyframes fadeInOut": {
          "0%": {
            opacity: 0,
          },
          "25%": {
            opacity: 1,
          },
          "75%": {
            opacity: 1,
          },
          "100%": {
            opacity: 0,
          },
        },
      }}
      {...other}
    >
      {condition ? text1 : text2}
    </Box>
  );
};
