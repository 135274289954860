import { Container, chakra, Flex, Box } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import { useGetEssaysPath } from "@/hooks/useGetEssaysPath";
import OptimizedImage from "@/components/shared/OptimizedImage";
import { LinkButton } from "@/components/shared/generic";
import { useDevice } from "@/contexts/DeviceContext";

export const EssaysDescription = () => {
  const essaysPath = useGetEssaysPath();
  const { isMobile } = useDevice();

  return (
    <Box position="relative" bg="#F1F7FF">
      <Container
        maxW="container.xl"
        paddingInlineStart={{ base: "2rem", "2xl": "1rem" }}
        paddingInlineEnd={{ base: "2rem", "2xl": "1rem" }}
      >
        <Flex
          pt={20}
          flexDir={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          gap={{ base: "4rem", md: "4rem" }}
          pb="8rem"
          textAlign={{ base: "center", md: "left" }}
        >
          <Flex flexDir="column" width={{ base: "100%", md: "50%" }}>
            <chakra.p fontSize="xs" fontWeight={700} textTransform="uppercase" color="#3CB2FD">
              GET INSTANT FEEDBACK ON YOUR DRAFT
            </chakra.p>

            <Box
              fontWeight={600}
              fontSize="2.875rem"
              fontFamily="'Raleway', sans-serif"
              lineHeight="116%"
              letterSpacing="-0.02875rem"
            >
              Supercharge your college essay.
            </Box>

            <chakra.p fontSize="md" mt={4}>
              Enhance your essay with our admissions-focused AI Essay Revision tool. Get instant
              feedback, constructive criticism, and step-by-step instructions on how to take your
              essay to the next level.
            </chakra.p>

            {!isMobile && (
              <LinkButton
                mt={8}
                colorScheme="transparent"
                href={essaysPath}
                shadow="-4px 4px 0px 0px #3CB2FD"
                rightIcon={<ArrowForwardIcon />}
                background="#FFFFFF"
                color="#000"
                border="1px solid #000"
                width={{ base: "100%", md: "fit-content" }}
              >
                Read more
              </LinkButton>
            )}
          </Flex>

          <Box width={{ base: "100%", md: "50%" }}>
            <OptimizedImage
              src={"/images/home/essays/essays.png"}
              alt="Profiles"
              width="620px"
              height="530px"
              objectFit="cover"
            />

            {isMobile && (
              <LinkButton
                mt={8}
                colorScheme="transparent"
                href={essaysPath}
                shadow="-4px 4px 0px 0px #3CB2FD"
                rightIcon={<ArrowForwardIcon />}
                background="#FFFFFF"
                color="#000"
                border="1px solid #000"
                width={{ base: "100%", md: "fit-content" }}
              >
                Read more
              </LinkButton>
            )}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
