import { ArrowDownIcon } from "@chakra-ui/icons";
import { Container, chakra, Flex, Button, Box, Heading } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import { GradientText, SkewedContainer } from "@/components/shared/generic";
import { TrustedUsers } from "../TrustedUsers";
import { TestimonialList } from "../Testimonials";
import { motion } from "framer-motion";

export const OtherTestimonials = ({ testimonials }) => {
  const headingRef = useRef();
  const listBottomRef = useRef();

  const [visible, setVisible] = useState(false);

  const textSuccessful = {
    from: "#EC538A 61.12%",
    to: "#842A62 139.29%",
    fontSize: { base: "0.95em", md: "1em" },
    degree: "200deg",
    display: "inline",
    mobileBg: "#EC538A",
  };

  useEffect(() => {
    if (headingRef.current) {
      const handleScroll = () => {
        const headingYPos = headingRef.current.getBoundingClientRect().top;

        const listBottom = listBottomRef.current.getBoundingClientRect().top;

        setVisible(headingYPos < 0 && listBottom > window.innerHeight);
      };

      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <Box bg="#17163B">
      <SkewedContainer
        bg="#17163B"
        containerProps={{
          maxW: "container.xl",
          py: "5rem",
          paddingInlineStart: { base: "2rem", xl: "1rem" },
          paddingInlineEnd: { base: "2rem", xl: "1rem" },
        }}
        top={{ base: "-6em", sm: "-6em", md: "-8em", lg: "-8em", xl: "-8em", "2xl": "-9em" }}
        mt={{ base: 0, md: 10 }}
      >
        <chakra.p
          fontSize="xs"
          fontWeight={700}
          textAlign={{ base: "center", md: "left" }}
          textTransform="uppercase"
          color="#fff"
        >
          Testimonials
        </chakra.p>

        <Flex
          flexDir={{ base: "column", md: "row" }}
          alignItems="center"
          justify="space-between"
          mb={6}
          gap={{ base: 2, md: 0 }}
          textAlign={{ base: "center", md: "left" }}
        >
          <Heading as="h1" ref={headingRef} fontSize={{ base: "4xl", md: "5xl" }} color="#fff">
            People <GradientText {...textSuccessful}>love us.</GradientText>
          </Heading>

          <Flex borderRadius="0.39rem" bg="white" boxShadow="-4px 4px 0px 0px #2D3644" p={2.5}>
            <TrustedUsers testimonials={testimonials.preview} />
          </Flex>
        </Flex>

        <TestimonialList
          rawTestimonials={testimonials.columns}
          bg="linear-gradient(38deg, #18173C 17.64%, rgba(83, 22, 60, 0.88) 89.72%)"
        />

        <Box ref={listBottomRef} h={1} />
      </SkewedContainer>

      <AnimatePresence>
        {visible && (
          <Flex
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={0}
            align="end"
            justify="center"
            position="fixed"
            bottom="0"
            h="40vh"
            bg={`linear-gradient(transparent, #17163B)`}
            w="100vw"
            zIndex={1}
          >
            <Button
              mb={4}
              size="lg"
              colorScheme="#17163B"
              onClick={() =>
                document.getElementById("afterTestimonials").scrollIntoView({ behavior: "smooth" })
              }
            >
              Okay, I get the point.
              <ArrowDownIcon ml={2} />
            </Button>
          </Flex>
        )}
      </AnimatePresence>
    </Box>
  );
};
