import { chakra, Flex, Box, Text } from "@chakra-ui/react";

import { GradientText, SkewedContainer } from "@/components/shared/generic";

export const AboutUs = () => {
  const textSuccessful = {
    from: "#EC538A 61.12%",
    to: "#842A62 139.29%",
    fontSize: { base: "0.95em", md: "1em" },
    degree: "200deg",
    display: "inline",
    mobileBg: "#EC538A",
  };

  return (
    <Box zIndex={1} position="relative">
      <SkewedContainer
        bg="white"
        containerProps={{
          maxW: "container.xl",
          paddingInlineStart: { base: "2rem", xl: "1rem" },
          paddingInlineEnd: { base: "2rem", xl: "1rem" },
        }}
        top={{ base: "-8em", sm: "-8em", md: "-9em", lg: "-10em", xl: "-8em", "2xl": "-9em" }}
      >
        <Flex
          flexDir={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          gap={{ base: "2rem", md: "9rem" }}
          my={{ base: 0, md: 20 }}
          mb={{ base: 20, md: 0 }}
        >
          <Flex flexDir="column" textAlign={{ base: "center", md: "left" }}>
            <GradientText
              {...textSuccessful}
              fontSize="xs"
              fontWeight={700}
              textTransform="uppercase"
            >
              About us
            </GradientText>

            <chakra.p
              fontWeight={600}
              fontSize="2.85rem"
              fontFamily="'Raleway', sans-serif"
              maxW={"16rem"}
            >
              What is AdmitYogi?
            </chakra.p>
          </Flex>

          <Box
            fontSize={{ base: "2xl", md: "3xl" }}
            fontWeight={600}
            fontFamily="'Raleway', sans-serif"
            textAlign={{ base: "center", md: "left" }}
          >
            <Text fontSize="2rem" display="inline-block" mr={2}>
              🎓
            </Text>
            Born at <GradientText {...textSuccessful}>Stanford</GradientText> and fueled by
            experiences of <GradientText {...textSuccessful}>thousands of applicants</GradientText>,
            AdmitYogi provides unparalleled perspectives on what constitutes a{" "}
            <GradientText {...textSuccessful}>successful college application</GradientText>, from
            academics and test scores to essays and scholarships.{" "}
          </Box>
        </Flex>
      </SkewedContainer>
    </Box>
  );
};
